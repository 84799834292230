/* eslint-disable implicit-arrow-linebreak  */
/* eslint-disable no-shadow  */

import React, { useContext } from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import Loading from 'shared/loading';
import { firebaseContext } from 'context/firebase-context';
import { useFormInput, useErrors, useSubmitReady } from 'hooks/useForm';

import s from './admin.module.scss';

export default function OrgasmicManifestingSystem() {
  // CONTEXT
  const { auth, admin } = useContext(firebaseContext) || {};

  // CUSTOM  HOOKS
  const email = useFormInput('');
  const password = useFormInput('');
  const emailErrors = useErrors({ email: email.state });
  const passwordErrors = useErrors({ name: password.state });
  const textInputsReady = useSubmitReady({
    email: email.state,
    password: password.state
  });

  // METHODS
  const login = ({ email, password }) => auth.signInWithEmailAndPassword(email, password);
  const logout = () => auth.signOut();

  const submitReady = textInputsReady;
  const handleSubmit = async e => {
    e.preventDefault();
    const form = {
      email: email.state.value,
      password: password.state.value
    };
    await login(form);
  };

  if (!auth || !admin) return <Loading />;
  return (
    <Container className={s.root}>
      {admin.displayName ? (
        <React.Fragment>
          <Headline>YOU'RE IN</Headline>
          <Button tertiary type="submit" noArrow onClick={logout}>
            Logout
          </Button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Headline h1>LOGIN</Headline>
          <br />
          <form onSubmit={handleSubmit}>
            <p>Email</p>
            <input
              type="text"
              name="email"
              {...email.events}
              value={email.state.value}
            />
            <br />
            <br />
            <p>Password</p>
            <input
              type="password"
              name="password"
              {...password.events}
              value={password.state.value}
            />
            <br />
            <br />
            <Button
              tertiary
              type="submit"
              disabled={!submitReady}
              className={s.submit}
            >
              Submit
            </Button>
          </form>
        </React.Fragment>
      )}
    </Container>
  );
}
